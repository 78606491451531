#root>span>.container>.row {
    margin-left: 0px;
    margin-right: 0px;
}

.navbar {
    background-color: #060646;
    padding: 0px;
}

.navbar-clearfix a {
    height: 54px;
}

.navbar-entry {
    color: #fff !important;
}

.navbar-brand {
    color: #fff !important;
}

.navbar-nav li a[role=button] {
    color: #fff !important;
}

.navbar-nav .nav-item {
    color: #fff !important;
    margin-right: 10px;
}

.navbar-nav li a:hover,
.navbar-nav li.active a {
    color: orange !important;
    background-color: #fff !important;
}

.navbar-text {
    color: #fff !important;
}

#projectSelection .nav-item {
    width: 100%;
}

a.nav-link {
    font-size: 14px !important;
    color: #337ab7;
}

#projectSelection .nav-item a.nav-link {
    padding: 12px;
}

#projectSelection .nav-item a.active.nav-link {
    background-color: #060646;
    border-radius: 3px;
    padding: 10px;
    color: #fff;
}

.nav-stacked>li.active>a {
    background-color: #060646;
}

.nav-stacked>li.active>a:hover {
    background-color: #060646;
}

.nav-stacked>li.active>a:focus {
    background-color: #060646;
}

.nav-tabs {
    margin-bottom: 0;
}

.navbar-toggler {
    position: relative;
    float: right;
    padding: 7px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    font-size: 12px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    background-color: #ddd;
}

.navbar-brand {
    cursor: pointer;
}

.navbar-btn svg {
    font-size: 25px !important;
}

/* === For phones =================================== */
@media (max-width: 767px) {
    .navbar-btn-sm {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        margin-top: 2px;
        margin-left: -25px;
    }
}

/* === For tablets ================================== */
@media (min-width: 768px) and (max-width:991px) {
    .navbar-btn-sm {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        margin-top: 2px;
        margin-left: -25px;
    }
}

/*********************** Responsive ****************************/

@media (min-width: 320px) and (max-width: 767px) {
    .navbar-header {
        margin-left: 0px !important;
    }
}

.panel-heading .panel-title a:hover {
    cursor: pointer;
}

.cust-panel .panel-collapse .panel-body {
    padding: 0px;
}

.padding-less .panel-body {
    padding: 0px;
}

.buttonPanel .btn {
    margin-right: 5px;
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.no-padding .card-body {
    /* padding-right: 0;
    padding-left: 0; */
}

.no-margin-bottom {
    margin-bottom: 0px;
}

.correct-img-size {
    width: 24px;
    height: 24px;
}

.center-img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.tab-pane {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-radius: 0px 0px 5px 5px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
}

.ReactTable {
    margin-top: 5px;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 249, 0.6);
    color: white;
    cursor: pointer;
}

.rt-td img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.rt-td .checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
}

.popover {
    z-index: 1029;
    max-width: 100%;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.fullheight {
    height: 100%;
}

.logo {
    padding-top: 20%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.loader {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.modal-x-large {
    width: 990px !important;
    font-size: 14px !important;
}

.btn .fa-check {
    color: green;
}

.btn .fa-times {
    color: red;
}

.rt-th:focus {
    outline: none
}

.no-border-shadow {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}

div.rt-table {
    font-size: 14px !important;
}

.tableSize {
    height: calc(100vh - 260px);
}

.sliderStepTooltip {
    width: 200px;
}

.buttonPanel img {
    height: 22px;
}


.ReactTable .rt-thead {
    flex: none !important;
}

.ReactTable .rt-tr {
    flex: none !important;
}

.ReactTable .rt-tr-group {
    flex: none !important;
}

/* Netplan-Tab*/
#tabs-pane-NET_PLANE_VIEW .leaflet-container {
    height: calc(86vh - 258px) !important;
    min-height: 10vh !important;
}

#tabs-pane-NET_PLANE_VIEW #netplaneview {
    margin-bottom: 0px !important;
}

/*
#tabs-pane-NET_PLANE_VIEW #netplanview{
    height: calc(85vh - 190px)  !important;
}*/

/* Netplan Sideview */
#sideTabs-pane-NETPLANE_SIDE_VIEW {
    height: calc(90vh - 70px) !important;
}

#sideTabs-pane-NETPLANE_SIDE_VIEW .leaflet-container {
    height: calc(78vh - 80px) !important;
    min-height: 10vh !important;
}

/* Objectlist Sideview */
#sideTabs-pane-OBJECT_LIST_SIDE_VIEW .ReactTable {
    height: calc(90vh - 80vh) !important;
}

a[role=tab] {
    padding: 12px;
}

a[role=tab].active {
    border: 1px solid #ddd;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid white;
    margin-bottom: -1px;
    color: black;
    z-index: 1000;
}

div[role=tablist] {
    border-bottom: 1px solid #ddd !important;
}

div.card {
    margin-top: 5px;
    padding: 0;
}

.view-container {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.card-body .row .row .control-label {
    font-size: 12px;
    text-align: end;
}

.card-body .row .row {
    margin-bottom: 12px;
    margin-right: 0px;
    margin-left: 0px;
}

.card-header {
    font-size: 16px;
    color: #333333 !important;
    font-weight: 400;
    background-color: #eee !important;
}

.card-body .form-control {
    border: 1px solid #ddd;
    background-color: #eee;
}

input.form-control {
    font-size: 12px;
}

.control-label {
    display: flex;
    justify-content: right !important;
    align-items: center !important;
}

.btn-primary {
    background-color: white !important;
    color: black;
    margin-left: 15px;
    border-color: #ddd;
}

button.btn-primary:disabled {
    background-color: #ddd !important;
    border-color: #ddd;
    color: black;
}

.btn-primary.slider-step {
    color: black !important;
}

.navbar-nav a[role=button].nav-link div.nav-item {
    height: 100% !important;

}

.navbar-nav a[role=button].nav-link div.nav-item:hover {
    background-color: white;
    color: orange !important;
}

.height-100p {
    min-height: 62px !important;
}

.height-100p .navbar-nav a {
    min-height: 62px !important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.height-100p .navbar-nav a .nav-item {
    min-height: 100% !important;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}

.no-margin {
    margin: 0 !important;
}

.down {
    margin-bottom: -10px !important;
}

.navbar button {
    margin-left: 12px;
}

button svg {
    font-size: 10px;
}

div[role=tooltip] strong {
    padding: 14px !important;
    font-size: 16px;
}

div[role=tabpanel] .row {
    padding: 0px;
}

div[role=group].btn-group {
    margin-right: 8px;
}

div[role=group] label.btn {
    margin-right: 0px;
    margin-left: 0px;
}

div[role=group] button {
    margin-right: 0px;
    margin-left: 0px;
    color: black !important;
}

#tabs-tabpane-PANORAMO_MAIN_VIEW {
    padding: 4px;
}

.flex-direction-row {
    display: flex;
    flex-direction: row !important;
}

.tab-content .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

form>.card .card-body .card {
    margin-top: -17px !important;
    margin-bottom: -17px !important;
    margin-left: -7px !important;
    margin-right: -6px !important;
}

form>.card .card-body.flex-direction-row .card {
    margin-left: -1px !important;
    margin-right: -1px !important;
}

.container .row .no-border-shadow:first-child>.card-body {
    padding-left: 8px;
    padding-right: 0px;
}

.container .row:last-child .no-padding:last-child .card-body {
    padding-left: 0px;
    padding-right: 0px;
}

div.manholeTabs {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 0px !important;
    padding-bottom: 4px !important;
}

.sideview-container {
    height: 93vh;
}

.sideview-container .card-body {
    height: 100%;
}

.sideview-container .card-body .row {
    height: 100%;
    align-content: start;
}

.sideview-container .card-body .row>div:first-child {
    height: 50px;
}

.sideview-container .card-body .row>div:last-child {
    height: calc(100% - 60px);
}

.sideview-container .card-body .row:last-child .tab-content {
    height: 100%;
}

.sideview-container .card-body .row:last-child .tab-content .tab-pane {
    height: 100%;
}

#sideTabs-tabpane-OBJECT_LIST_SIDE_VIEW .ReactTable {
    height: 99%;
}

.navbar-collapse {
    display: flex;
    margin-left: 16px;
    justify-content: space-between;
}

.navbar-breadcrump {
    display: flex;
}

@media (max-width: 1200px) {
    .navbar-collapse {
        flex-direction: column;
        justify-content: end;
        align-items: end;
    }
}

#tabs-tabpane-PANORAMO_MAIN_VIEW>.card>.card-body>.container-fluid>.row,
#tabs-tabpane-STATION_MAIN_VIEW>.card>.card-body>.container-fluid>.row {
    margin-bottom: 0px !important;
}

#sideTabs-tabpane-NETPLANE_SIDE_VIEW {
    max-height: '80vh' !important;
}

.ComplexTextBox.input-group button.btn.bt-primary.btn-small {
    margin-right: 0px !important;
}

.container-fluid>.row>.col>.btn {
    margin-right: 8px;
}