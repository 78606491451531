.settings-fake-tab {
	float: right !important;
}

.settings-fake-tab-offset {
	margin-right: 20px;
}

.highlight-settings-type {
	font-style: italic;
	font-weight: bold;
}

.buttonObjectListSetting {
    padding-top: 8px;
    padding-bottom: 8px;
}

.buttonObjectListSetting svg {
	height: 14px;
}

.sortableHelper {
	z-index: 10000 !important;
	cursor: move;
}

@media (min-width: 769px) and (max-width: 1024px) {
	.sortableHelper > span {
		font-size: 12px;
	}
}

.larger-list {
	margin-left: -5px;
	margin-right: -5px;
}

.objectlist-right {
	float: right;
}

.single-settings-btn {
	float: right;
	width: 85px;
	margin-top: -6px;
	margin-left: 4px;
}

.duo-settings-btn {
	float: right;
	width: 40px;
	margin-top: -6px;
	margin-left: 4px;
}

.ReactTable .rt-tbody {
	overflow: visible;
}
