.col-height {
    max-height: 45px;
    height: 30px;
}

.label-wrap {
    width: 100%;
    overflow-wrap: break-word !important;
    /* word-wrap: break-all !important;*/

}

.correct-padding {
    padding-right: 0px;
    padding-left: 23px;
}

@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
        font-size: 11px;
    }
}

.body-no-padding {
    padding-left: 5px;
    padding-right: 5px;
}

.columnImg {
    margin-left: unset !important;
    margin-right: 10px !important;
    display: unset !important;
}

form .nav-tabs {
    margin-top: 5px;
}