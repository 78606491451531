.login {
    margin-top: 15vh;
}

.login .panel .panel-heading {
    background-color: #060646;
    color: #fff;
}

.login-loader {
    position: absolute;
    z-index: 3;
    top: 180px;
    left: 45%
}

.headerText{
    color: #060646;
    font-weight: bolder;
}

.login .card-header {
    background-color: #060646 !important;
    color: white !important;
}

button.btn-primary {
    font-size: 14px;
}

button.btn-primary:hover {
    color: black;
    background-color: #eee !important;
}

.input-group-text {
    padding: 10px;
    padding-left: 14px;
    padding-right: 14px;
    background-color: #eee !important;
}

.input-group input{
    background-color: white !important;
}