.slider-wrapper {
	width: 960px;
	margin: auto;
}
.slick-slide {
	text-align: center;
	position: relative;
}
.slick-slide:focus {
	outline: none;
}
.slick-slide-title {
	text-transform: capitalize;
	font-size: 1.2em;
}
.slick-slide-image {
	max-width: 100%;
	height: auto;
}

.slick-prev:before,
.slick-next:before {
	color: #777777;
}

.slick-prev {
	left: -52px !important;
}

.slick-next:before,
.slick-prev:before {
	content: '' !important;
}
.next-slick-arrow,
.prev-slick-arrow {
	color: #000000;
	font-size: 48px;
}
