.row-map {
  margin-top:10px;
}
.row-progress {
  margin-top:2px;
}
.sliderPlan, .sliderMap { margin-top:5px; width: 98%; }

.map {
  min-height: 70vh;
  width: 100%;
}
.leaflet-control-layers-overlays label div {
  text-align: left;
}

.divider-LayersControl {
  height: 1px;
  width:100%;
  display:block; /* for use on default inline elements like span */
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.tooltip-land-register {
  background: none;
  box-shadow: none;
  border: none;
}
