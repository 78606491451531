#settingsmodal {
    width: 90vw !important;
    max-width: 90vw !important;

    .buttonCard {
        margin-bottom: 16px;
    }

    .settings-row {
        padding-left: 0px !important;
    }

    .list-group-item {
        padding-top: 10px;
        padding-bottom: 4px;
        font-size: 14px !important;
    }

    .btn:first-child:active,
    .btn.active,
    .btn.show {
        color: black;
    }

    #oblist-tabs button {
        padding-right: 0px !important;
        margin-right: 0px !important;
    }
}

.settings-btn {
    float: left;
}

.layout-label {
    margin-top: 15px
}

.title-form {
    font-size: 20px;
    font-weight: bold;
}

.title-form>span {
    float: right;
}

.slider-adjust {
    padding-top: 15px !important;
    margin-left: 10px;
}

.help-text-adjust {
    padding-top: 7px;
}

.help-text-adjust-extra {
    padding-top: 18px;
}


#settings-tab-container {
    margin-top: 16px;
}

.settings-tabs {
    color: black !important;
    padding-left: 8px;
    padding-right: 8px;
}

#settings-tab-container .tab-content {
    padding: 0;
}

.settings-form-group {
    padding: 16px !important;
}

.settings-form-group input {
    z-index: 1000;
}

.icon-button {
    font-size: 14px;
    vertical-align: middle;
}