.slider-step {
    width: 50px;
}

div.popover-arrow {
    display: none !important;
}

/* .sliderStepTooltip {
    display: flex;
    align-items: center;
} */

@media (min-width: 760px) {
    #ibak-pano-target {
        position: relative;
        top: 0px;
        left: 0px;
        height: 70vh;
        width: 100%;
    }
}

.icon-button {
    font-size: 20px;
}

.icon-button:hover {
    font-size: 20px;
}

#POPOUTPORTAL>.row {
    width: 100vw;
    margin-top: 20px;
}

#panocontainer {
    height: 100%;
    width: 100%;
}

#panoWrapTargetContainer {
    height: 100%;
    min-height: 1px;
    width: 100%;
}

.wrapContainer {
    display: flex;
    height: 50%;
    flex-direction: column;
    flex-wrap: wrap;
}

.wrapContainer.manhole {
    flex-direction: row;
}

.popout .wrapcontainer {
    margin: 0px !important;
}

#panoWrapOverviewTargetContainer {
    height: 100%;
    min-height: 1px;
    width: 100%;
}

#pointclouddiv {
    height: 100vh;
    width: 100vw;
    position: relative;
}

#pointcloudmain {

    height: 100vh;
    width: 100vw;
}

#pointcloudoverview {
    right: 0px !important;
    bottom: 0px !important;
    z-index: 102 !important;
    height: 20vh;
    width: 20vw;
    position: absolute !important;

}

#panoramoCard {
    height: 75vh;
}

#panoramoCard.popout {
    height: 90vh;
}